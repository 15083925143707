import AboutCollege from "../ui/AboutCollege";

const AboutCollegePage = () =>{
    return(
        <div style={{height:'max-content'}}>
            <div style={{paddingTop:'13vh',paddingBottom:'22.5vh'}}>
                <AboutCollege/>
            </div>
           
        </div>

    )
}
export default AboutCollegePage;