// Under construction ig
const VisaPage = () =>{
    return(
        <div 
            style={{
                color:'#EBF4FF',
                fontWeight:'700',
                height:'70vh',
                textAlign:'center'
            }} 
        >
            <h1
                style={{
                    padding:'20px'
                }}
            >To be Announced</h1>
        </div>

    )
}
export default VisaPage;